import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import PageHeader from "../components/PageHeader"
import OfficialListItem from "../components/ListItems/OfficialListItem"

const OfficialsPage = () => {
  const data = useStaticQuery(graphql`
    {
      officials: allNodeOfficial(
        filter: { status: { eq: true } }
        sort: {
          order: ASC
          fields: [relationships___field_function___weight, field_order]
        }
      ) {
        nodes {
          id
          title
          body {
            value
          }
          image: field_image {
            alt
            width
            height
          }
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 352
                    maxHeight: 423
                    cropFocus: CENTER
                    quality: 100
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            function: field_function {
              tid: drupal_internal__tid
              name
              weight
            }
          }
        }
      }
    }
  `)

  const officials = data.officials.nodes

  const officialsByFunction = []

  officials.forEach(node => {
    let group = node.relationships.function

    if (officialsByFunction[group.tid] === undefined) {
      officialsByFunction[group.tid] = {
        name: group.name,
        weight: group.weight,
        nodes: [],
      }
    }

    officialsByFunction[group.tid].nodes.push(node)
  })

  const sortedOfficialsByFunction = officialsByFunction.sort(
    (a, b) => Number(a.weight) - Number(b.weight)
  )

  return (
    <Layout>
      <PageHeader title="Vos élus" />
      <Metas
        title="Vos élus"
        description="Les élus du conseil municipal de Coucy"
      />
      <section className="section-bottom page-content">
        <div className="container mx-auto px-4">
          {sortedOfficialsByFunction.map((group, index) => (
            <div key={index} className={`group term-${index}`}>
              <h2 className="my-8 text-primary">{group.name}</h2>
              <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                {group.nodes.map((node, index) => (
                  <div key={index} className="column official">
                    <OfficialListItem content={node} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default OfficialsPage
