import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Small16by10 from "../Images/Small16by10"
import HtmlParser from "../HtmlParser"

const OfficialListItem = ({ content }) => (
  <div className="article-list-item relative">
    <div className="list-item-image relative">
      {content.relationships.image ? (
        <Img
          fluid={content.relationships.image.localFile.childImageSharp.fluid}
          alt={content.title}
        />
      ) : (
        <Small16by10 alt={content.title} />
      )}
    </div>
    <div className="list-item-content p-4 bg-white">
      <div className="title h3 mb-0 text-secondary">{content.title}</div>
      <div className="content">
        <HtmlParser html={content.body.value} />
      </div>
    </div>
  </div>
)

OfficialListItem.propTypes = {
  content: PropTypes.object.isRequired,
}

export default OfficialListItem
